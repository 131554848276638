import { createApp } from 'vue'

import $eventBus from "./services.js"

import SearchResults from './components/SearchResults.vue'
import AdvancedSearchButton from "./components/advanced-search/AdvancedSearchButton.vue"
import AdvancedSearchModal from "./components/advanced-search/AdvancedSearchModal.vue"
import DynastyButton from "./components/dynasty/DynastyButton.vue"
import VoiceSearchWidget from "./components/VoiceSearchWidget.vue"

import './assets/styles.css'

document.getElementById("search_results") && createApp(SearchResults).mount('#search_results')

const app = createApp();
app.use($eventBus)

app.component('advanced-search-button', AdvancedSearchButton);
app.component('advanced-search-modal', AdvancedSearchModal);
app.component('dynasty-button', DynastyButton);
app.component('voice-search-widget', VoiceSearchWidget);
app.mount("#navbar");

