<template>

  <button
    v-if="!isRecording"
    @click="startRecording"
    type="button"
    class="btn rounded-0"
    data-bs-toggle="tooltip"
    title="Start Recording">
    <i class="bi bi-mic-fill"></i>
  </button>

  <button
    v-if="isRecording"
    @click="stopRecording"
    type="button"
    class="btn rounded-0"
    data-bs-toggle="tooltip"
    title="Stop Recording">
    <i class="bi bi-stop-fill"></i>
  </button>

  <div v-if="!!isRecording || audioUrl" class="btn rounded-0">
    {{ timeCounter }}s
  </div>

 <button
    v-if="audioUrl && !isRecording"
    @click="playRecording"
    type="button"
    class="btn rounded-0"
    data-bs-toggle="tooltip"
    title="Play Recording">
    <i class="bi bi-play-fill"></i>
  </button>

  <button
    v-if="audioUrl && !isRecording"
    @click="resetRecording"
    type="button"
    class="btn rounded-0"
    data-bs-toggle="tooltip"
    title="Reset Recording">
    <i class="bi bi-x-circle-fill"></i>
  </button>

  <input ref="fileInput" type="file" name="humming" hidden>

  <!-- Audio Player (Hidden, Used for Playing Recording) -->
  <audio ref="audioPlayer" :src="audioUrl" hidden>
  </audio>

</template>

<script>
import { ref, watch } from 'vue';

export default {
  setup() {
    const mimeTypes = ['audio/webm', 'audio/mp4'];
    const isRecording = ref(false);
    const timeCounter = ref(0);
    const audioUrl = ref(null);
    const audioChunks = ref([]);
    let intervalId;
    let mediaRecorder = null;
    let mediaStream = null;
    const audioPlayer = ref(null);
    const fileInput = ref(null);
    let mimeType = null;

    async function startRecording() {
      if (isRecording.value) {
        throw new Error('Recording is already in progress!');
      }
      resetRecording();
      if(!mediaStream) try {
          mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch (error) {
        console.error('Error accessing microphone:', error);
      }
      if (!mimeType) {
        mimeType = mimeTypes.find(type => MediaRecorder.isTypeSupported(type)) || mimeTypes[1];
      }
      try {
        mediaRecorder = new MediaRecorder(mediaStream, { mimeType });
        mediaRecorder.ondataavailable = (event) => {
          audioChunks.value.push(event.data);
        };
        mediaRecorder.onstop = () => {
          try {
            const audioBlob = new Blob(audioChunks.value, { type: mimeType });
            const dataTransfer = new DataTransfer();
            if (audioBlob.size > 0) {
              audioUrl.value = URL.createObjectURL(audioBlob);
              const audioFile = new File([audioBlob], 'recording.wav', { type: audioBlob.type });
              dataTransfer.items.add(audioFile);
            } else {
              audioUrl.value = null;
            }
            fileInput.value.files = dataTransfer.files;
          } finally {
            mediaStream.getTracks().forEach(track => track.stop());
          }
        };
        timeCounter.value = 0;
        audioChunks.value = [];
        mediaRecorder.start();
        isRecording.value = true;
        intervalId = setInterval(() => ++timeCounter.value, 1000);
      } catch (error) {
        console.error('Error starting recording:', error);
      }
    }

    function stopRecording() {
      if (!mediaRecorder)
        throw new Error('Recording is not in progress!');
      mediaRecorder.stop();
      isRecording.value = false;
      clearInterval(intervalId);
    }

    function playRecording() {
      const player = audioPlayer.value;
      if (player && audioUrl.value) {
        player.play();
      } else {
        console.error('Audio player or audio URL is missing!');
      }
    }

    function resetRecording() {
      isRecording.value = false;
      timeCounter.value = 0;
      audioUrl.value = null;
      if (audioPlayer.value) {
        audioPlayer.value.pause();
        audioPlayer.value.currentTime = 0;
      }
      mediaRecorder = null;
      mediaStream = null;
      fileInput.value.value = '';
    }

    watch(audioUrl, x => {
      const form = audioPlayer.value.closest('form');
      if (form) {
        form.method = x ? 'POST' : 'GET';
        form.enctype = x ? 'multipart/form-data' : '';
      }
    });

    return {
      isRecording,
      timeCounter,
      audioUrl,
      startRecording,
      stopRecording,
      playRecording,
      resetRecording,
      audioPlayer,
      fileInput
    };
  },
};
</script>
